/* src/App.css */
@import url('https://fonts.googleapis.com/css2?family=Special+Elite&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap');

body {
  font-family: 'Special Elite', cursive;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  margin: 50px 150px;
  text-align: center;
}

h1 {
  font-size: 2rem;
}

.emoji-container {
  text-align: center;
}

.puzzle-type {
  font-size: 4rem;
}

.emojis {
  font-size: 5rem;
}

.guess-input {
  font-size: 4rem;
  padding: 0.5rem;
  margin: 3rem;
  width: 100%;
  min-height: 3rem;
  border: none;
  overflow-y: hidden; /* Hide vertical scrollbar */
  letter-spacing: 0.3rem;
  font-family: "Cutive Mono", monospace, 'Times New Roman', Times, serif, 'Georgia', 'Garamond', 'Palatino Linotype', 'Book Antiqua';
  font-weight: 600;
  text-align: center;
  color: black;
  align-content: center;
  height: auto;
}

.guess-input:focus-visible {
  border: none;
  outline: none;
}

.input-error {
  animation: inputErrorAnimation 1s forwards;
}

@keyframes inputErrorAnimation {
  0% {
    background-color: lightcoral;
  }
  100% {
    background-color: transparent;
  }
}

.submit-button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px 11px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: 'Special Elite', cursive;
  font-size: 1.5rem;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #45a049;
}

.hints {
  margin-top: 1rem;
  font-size: 1.5rem;
}

.hints div {
  margin-top: 0.5rem;
}

.footer {
  font-size: 0.8rem;
  margin-bottom: 5px;
  margin-top: 30px;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}

.skip-button {
  background-color: transparent;
  border: none;
  color: white;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.5rem;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.skip-button:disabled {
  opacity: 20%;
  cursor: auto;
}

.skip-button:hover:not(:disabled) {
  background-color: #4CAF50;
}

/* Responsive Design */
@media (max-width: 768px) {
  .App {
    margin: 20px;
  }

  h1 {
    font-size: 1.5rem;
  }

  .puzzle-type {
    font-size: 3rem;
  }

  .emojis {
    font-size: 4rem;
  }

  .guess-input {
    width: 100%;
    padding: 5px;
    font-size: 2.5rem;
    margin: 2rem;
  }

  .submit-button {
    padding: 10px 20px;
    font-size: 1.2rem;
  }

  .hints {
    margin-top: 1rem;
    font-size: 1rem;
  }
}

@media (max-width: 390px) {
  .App {
    margin: 5px;
  }

  .puzzle-type {
    font-size: 2.5rem;
  }

  .emojis {
    font-size: 2rem;
    width: 100%;
  }

  .guess-input {
    font-size: 1.5rem;
  }

  .submit-button {
    padding: 5px 10px;
    font-size: 1rem;
  }
}

@media (max-height: 768px) and (min-width: 400px) {
  h1 {
    font-size: 1.5rem;
  }

  .puzzle-type {
    font-size: 3rem;
  }

  .emojis {
    font-size: 4rem;
  }

  .guess-input {
    margin: 1rem;
  }

  .hints {
    margin-top: 1rem;
    font-size: 1rem;
  }
}